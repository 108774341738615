<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { autoUpdate, offset, shift, size, useFloating } from '@floating-ui/vue'

const { t } = useI18n()

const claims = useClaims()

const activeIdCookie = useActiveClaim()
const activeId = computed<number | undefined>({
  get: () => Number.parseInt(`${activeIdCookie.value}`, 10) || undefined,
  set: (value) => {
    activeIdCookie.value = !value ? undefined : `${value}`
  },
})

const claim = computed(() => {
  return claims.value.find(c => c.id === activeId.value)
})

const localePath = useLocalePath()
function handleAddClaim() {
  activeId.value = undefined
  navigateTo(localePath('developer-claim'))
}

const route = useRoute()
const getRouteBaseName = useRouteBaseName()
const baseRouteName = computed(() => {
  return getRouteBaseName(route)
})

const button = ref(null)
const { width, height } = useElementSize(button)
const hover = useState('dev-claims-dropdown-hover', () => false)
const buttonSize = useState('dev-claims-dropdown-size', () => ({ width: 0, height: 0 }))

watch([width, height], ([width, height]) => {
  buttonSize.value = { width, height }
})

const floating = ref(null)
const { floatingStyles } = useFloating(button, floating, {
  whileElementsMounted: autoUpdate,
  middleware: [offset(4), size(), shift({ padding: { right: 6, left: 6 } })],
  placement: 'bottom-start',
})
</script>

<template>
  <Menu
    v-if="claim && claims.length && baseRouteName !== 'developer-claim'"
    v-slot="{ open }"
    as="div"
    class="relative"
  >
    <MenuButton
      ref="button"
      class="max-w-[248px] relative p-0.5 h-9 md:h-11 flex rounded-full border-dominant-color"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <span class="absolute left-2.5 px-0.5 md:left-4 -top-1 bg-white lg:text-[10px] text-[8px] leading-[10px] tracking-[0.03em] font-bold text-gray-400">
        <span class="max-md:hidden">{{ t('app') }}</span>
        <span class="md:hidden">{{ t('app-sm') }}</span>
      </span>

      <span class="flex items-center h-full pl-2.5 pr-2 md:pl-4 md:pr-4 bg-white rounded-full">
        <img
          v-if="claim.summary?.application.icon"
          :src="claim.summary?.application.icon"
          :alt="claim.summary?.application.title"
          width="24"
          height="24"
          class="w-5 h-5 rounded-md md:w-6 md:h-6"
        >
        <svg
          v-else
          class="w-5 h-5 md:w-6 md:h-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.75"
            y="0.75"
            width="22.5"
            height="22.5"
            rx="3.25"
            fill="white"
          />
          <rect
            x="0.75"
            y="0.75"
            width="22.5"
            height="22.5"
            rx="3.25"
            stroke="#E2E8F0"
            stroke-width="1.5"
          />
          <path
            d="M11.9999 11.9997C12.8735 11.9997 13.7119 12.2837 14.3294 12.7885C15.8445 14.026 15.0358 15.5371 14.5886 16.9995H9.41129C8.96411 15.5371 8.15615 14.026 9.67047 12.7885C10.288 12.2837 11.1264 11.9997 11.9999 11.9997ZM11.9999 11.9997C12.8735 11.9997 13.7119 11.7157 14.3294 11.211C15.8445 9.97344 15.0358 8.46152 14.5886 7H9.41129C8.96411 8.46152 8.15615 9.97344 9.67047 11.211C10.288 11.7157 11.1264 11.9997 11.9999 11.9997ZM7.76978 7H16.2301M7.76978 16.9995H16.2301"
            stroke="#94A3B8"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span class="hidden ml-3 mr-4 text-sm font-semibold text-left md:block">
          <span class="line-clamp-1">{{ claim.summary?.application.title || claim.name || 'App Request' }}</span>
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="24"
          viewBox="0 0 16 24"
          fill="none"
          class="flex-none h-5 md:h-6 max-md:ml-1"
        >
          <path
            d="M4.88892 7.65492L7.82973 5.06405C7.85175 5.0438 7.87818 5.02769 7.90745 5.01668C7.93671 5.00567 7.96821 5 8.00003 5C8.03185 5 8.06334 5.00567 8.09261 5.01668C8.12187 5.02769 8.14831 5.0438 8.17032 5.06405L11.1111 7.65492M4.88892 16.3451L7.82973 18.9359C7.85175 18.9562 7.87818 18.9723 7.90745 18.9833C7.93671 18.9943 7.96821 19 8.00003 19C8.03185 19 8.06334 18.9943 8.09261 18.9833C8.12187 18.9723 8.14831 18.9562 8.17032 18.9359L11.1111 16.3451"
            stroke="#334155"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </MenuButton>

    <Teleport to="body">
      <div v-if="open" ref="floating" :style="floatingStyles" class="z-[500]">
        <Transition
          appear-active-class="transition duration-100 ease-out"
          appear-from-class="transform scale-95 opacity-0"
          appear-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
          appear
        >
          <MenuItems id="claims-dropdown-items" class="flex flex-col w-full p-2 bg-white rounded-xl sm:w-80 shadow-fields-hover focus:outline-none min-w-[248px]">
            <MenuItem
              v-for="item in claims"
              v-slot="{ active }"
              :key="item.id"
              as="template"
            >
              <button
                type="button"
                class="relative flex p-2 text-left rounded-lg pr-9"
                :class="{
                  'bg-gray-100': active,
                }"
                @click="activeId = item.id"
              >
                <template v-if="!!item?.summary?.application">
                  <img
                    :src="item.summary.application.icon"
                    :alt="item.summary.application.title"
                    width="24"
                    height="24"
                    class="rounded-md"
                  >
                  <span class="ml-3 mt-0.5 text-sm font-bold">{{ item.summary.application.title }}</span>
                </template>
                <template v-else>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.75"
                      y="0.75"
                      width="22.5"
                      height="22.5"
                      rx="3.25"
                      fill="white"
                    />
                    <rect
                      x="0.75"
                      y="0.75"
                      width="22.5"
                      height="22.5"
                      rx="3.25"
                      stroke="#E2E8F0"
                      stroke-width="1.5"
                    />
                    <path
                      d="M11.9999 11.9997C12.8735 11.9997 13.7119 12.2837 14.3294 12.7885C15.8445 14.026 15.0358 15.5371 14.5886 16.9995H9.41129C8.96411 15.5371 8.15615 14.026 9.67047 12.7885C10.288 12.2837 11.1264 11.9997 11.9999 11.9997ZM11.9999 11.9997C12.8735 11.9997 13.7119 11.7157 14.3294 11.211C15.8445 9.97344 15.0358 8.46152 14.5886 7H9.41129C8.96411 8.46152 8.15615 9.97344 9.67047 11.211C10.288 11.7157 11.1264 11.9997 11.9999 11.9997ZM7.76978 7H16.2301M7.76978 16.9995H16.2301"
                      stroke="#94A3B8"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span class="ml-3 mt-0.5 text-sm font-bold">{{ item.name || 'App Request' }}</span>
                </template>
                <i-check
                  v-if="item.id === activeId"
                  class="absolute w-3 h-3 text-blue-500 right-2 top-3.5"
                />
              </button>
            </MenuItem>

            <hr class="my-2 border-gray-200">

            <MenuItem
              v-slot="{ active }"
              as="template"
            >
              <button
                type="button"
                class="flex items-center p-2 text-left rounded-lg"
                :class="{
                  'bg-gray-100': active,
                }"
                @click="handleAddClaim"
              >
                <span role="presentation" class="flex items-center justify-center w-6 h-6 mr-3 bg-gray-100 rounded-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8 2.34375V13.6575M2.34315 8.0006H13.6569H2.34315Z"
                      stroke="#94A3B8"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>

                {{ t('claim') }}
              </button>
            </MenuItem>
          </MenuItems>
        </Transition>
      </div>
    </Teleport>
  </Menu>
</template>

<style scoped>
@supports (background: paint(houdini)) {
  @property --gradPoint {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 280%;
  }
}

.border-dominant-color {
  --color1: #E2E8F0;
  --color2: var(--dominant-color, #E2E8F0);
  --gradPoint: 250%;

  background: theme('colors.gray.200');
  background: linear-gradient(175deg, var(--color1) 2%, var(--color2) var(--gradPoint));

  transition: --gradPoint 0.35s;
}

@supports (background: paint(houdini)) {
  .border-dominant-color:hover {
    --gradPoint: 80%;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "app": "App profile",
    "app-sm": "App",
    "claim": "Claim an app profile"
  },
  "fr": {
    "app": "Profil de l’appli",
    "app-sm": "Appli",
    "claim": "Réclamer un profil d’appli"
  }
}
</i18n>
