<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()
const searchAppGuideModal = useState('global-search-open', () => false)

const online = useChatwootOnlineAgents()
const { init, toggle } = useChatwoot()
onMounted(() => init())

const auroraBg = useAuroraBg()

const claims = useClaims()
const activeIdCookie = useActiveClaim()
const claim = computed(() => {
  return claims.value.find(c => c.id === Number.parseInt(`${activeIdCookie.value}`, 10) || undefined)
})

const dominantColor = computed(() => {
  if (!claim.value)
    return undefined

  return claim.value.summary?.dominant_color || undefined
})
</script>

<template>
  <div
    :style="{ '--dominant-color': dominantColor }"
    class="layout-developer text-pretty"
  >
    <Html>
      <Body class="leading-7 text-gray-700 bg-gray-100 font-body antialiased">
        <sidemenu id="sidemenu">
          <template #sub-logo>
            {{ t('sub-logo') }}
          </template>

          <sidemenu-item to="developer">
            <i-dashboard />
            {{ t('overview') }}
          </sidemenu-item>

          <sidemenu-group :name="t('clinician-guidance.name')">
            <sidemenu-item to="developer-trust-report">
              <i class="i-appguide-trust-report" />
              {{ t('clinician-guidance.trust-report') }}
            </sidemenu-item>
            <sidemenu-item
              v-if="claim?.summaryId"
              :to="{ name: 'apps-id', params: { id: claim?.summaryId } }"
              external
            >
              <i class="i-appguide-heart-eye" />
              {{ t('clinician-guidance.clinician-view') }}
            </sidemenu-item>
          </sidemenu-group>

          <sidemenu-group :name="t('assessment-services.name')">
            <sidemenu-item to="developer-assessment-all-inclusive">
              <i class="i-appguide-module-check" />
              {{ t('assessment-services.all-inclusive') }}
            </sidemenu-item>
            <sidemenu-item to="developer-assessment-pre-clinical">
              <i-heart-check />
              {{ t('assessment-services.pre-clinical') }}
            </sidemenu-item>
            <sidemenu-item to="developer-assessment-iso-82304-2">
              <i-box-iso />
              {{ t('assessment-services.iso-82304-2') }}
            </sidemenu-item>
          </sidemenu-group>

          <template #footer>
            <sidemenu-quick-action to="index">
              <i-home />
              {{ t('quick-actions.home') }}
            </sidemenu-quick-action>
            <sidemenu-quick-action @click="searchAppGuideModal = true">
              <i-search />
              {{ t('quick-actions.search') }}
            </sidemenu-quick-action>
            <sidemenu-quick-action @click="toggle('open')">
              <i-chat-bubble
                :online="online"
                bg-color="stroke-violet-900 group-hover:stroke-violet-400"
              />
              {{ t('quick-actions.live-chat') }}
            </sidemenu-quick-action>
          </template>
        </sidemenu>

        <navbar id="navbar" class="sticky top-0 justify-between">
          <DevClaimsDropdown />

          <UserMenu class="ml-auto" />
        </navbar>

        <DevUnderNav id="under-navbar" class="sticky top-0" />

        <div
          id="content"
          class="flex"
          :class="{
            'aurora-bg': auroraBg,
            'aurora-bg-sm': !auroraBg,
          }"
        >
          <div class="flex flex-col flex-1 mx-4 mt-10 mb-14 md:mb-24 md:mx-10 xl:mx-24 md:mt-12">
            <div>
              <slot />
            </div>

            <div class="pt-12 mt-auto md:pt-24">
              <footer class="flex items-center w-full gap-6 p-10 bg-gray-200 md:gap-0 md:justify-between max-md:flex-col rounded-2xl">
                <div class="flex gap-6 max-md:items-center max-2xl:flex-col 2xl:gap-10">
                  <img src="/logo/full.svg" alt="AppGuide" width="156" height="32">

                  <i18n-t
                    keypath="footer.mission"
                    tag="p"
                    class="text-xs md:text-sm font-display text-violet-400 max-md:text-center"
                  >
                    <br class="max-md:hidden">
                  </i18n-t>
                </div>

                <div class="text-xs text-center md:text-sm md:text-right">
                  <i18n-t keypath="footer.built-with" tag="p">
                    <template #hearth>
                      ❤️
                    </template>
                    <template #copy>
                      &copy;
                    </template>
                    <template #year>
                      {{ new Date().getFullYear() }}
                    </template>
                  </i18n-t>
                  <p>
                    <NuxtLink data-x-name="terms-and-conditions" :to="localePath('terms-and-conditions')" class="underline hover:text-blue-500">
                      {{ t('footer.terms') }}
                    </NuxtLink>
                    <span class="mx-1.5">•</span>
                    <NuxtLink data-x-name="privacy-policy" :to="localePath('privacy-policy')" class="underline hover:text-blue-500">
                      {{ t('footer.privacy') }}
                    </NuxtLink>
                  </p>
                </div>
              </footer>
            </div>
          </div>
        </div>

        <ClientOnly>
          <LazyBlockSearch v-if="searchAppGuideModal" />
          <LazyToaster />
        </ClientOnly>
      </Body>
    </Html>
  </div>
</template>

<style lang="scss" scoped>
.layout-developer {
  #navbar { grid-area: navbar; }
  #under-navbar { grid-area: navbar; }
  #sidemenu { grid-area: sidemenu; }
  #content { grid-area: content; }

  display: grid;
  min-height: 100vh;

  grid-template-rows: 56px 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "navbar"
                       "content";

  @screen lg {
    grid-template-rows: 72px 1fr;
    grid-template-columns: 296px 1fr;
    grid-template-areas: "sidemenu navbar"
                         "sidemenu content";
  }
}

.aurora-bg {
  background-image: url('/assets/textures/dev-content.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.aurora-bg-sm {
  background-image: url('/assets/textures/dev-background.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
}
</style>

<i18n lang="json">
{
  "en": {
    "sub-logo": "For Developers",
    "overview": "Overview",
    "clinician-guidance": {
      "name": "Decision Support Tools",
      "trust-report": "Trust Report",
      "clinician-view": "Clinician’s View"
    },
    "assessment-services": {
      "name": "Assessment Services",
      "all-inclusive": "All-Inclusive",
      "pre-clinical": "Pre-Clinical",
      "iso-82304-2": "ISO 82304-2"
    },
    "quick-actions": {
      "home": "Home",
      "search": "Search",
      "live-chat": "Live Chat"
    },
    "footer": {
      "mission": "Where you and your health professional{0} go for health apps",
      "built-with": "Built with {hearth} by TherAppX, {copy} {year} TherAppX Inc.",
      "terms": "Terms and conditions",
      "privacy": "Privacy policy"
    }
  },
  "fr": {
    "sub-logo": "pour développeurs",
    "overview": "Vue d'ensemble",
    "clinician-guidance": {
      "name": "Outils d'aide à la décision",
      "trust-report": "Rapport de confiance",
      "clinician-view": "Vue clinicien"
    },
    "assessment-services": {
      "name": "Services d'évaluation",
      "all-inclusive": "Tout inclus",
      "pre-clinical": "Pré-clinique",
      "iso-82304-2": "ISO 82304-2"
    },
    "quick-actions": {
      "home": "Accueil",
      "search": "Recherche",
      "live-chat": "Clavardage"
    },
    "footer": {
      "mission": "Où vous et votre professionnel allez{0} pour les applis santé",
      "built-with": "Construit avec {hearth} par TherAppX, {copy} {year} TherAppX Inc.",
      "terms": "Termes et conditions",
      "privacy": "Politique de confidentialité"
    }
  }
}
</i18n>
