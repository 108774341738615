<script setup lang="ts">
const skeleton = useSideMenuSkeleton()
const sidebar = useSideMenuToggle()
</script>

<template>
  <nav class="relative z-50 flex items-center w-full pr-4 bg-white shadow-fields md:pr-10 lg:px-10 xl:px-24">
    <button
      type="button"
      aria-label="Sidebar"
      :disabled="skeleton"
      class="flex items-center flex-none px-6 mr-6 md:mr-10 lg:hidden sidemenu-button"
      :class="{
        'justify-center': skeleton,
        'justify-between w-[120px]': !skeleton,
      }"
      @click="sidebar = true"
    >
      <span v-if="!skeleton" class="w-6 h-6 py-0.5 flex flex-col justify-between">
        <span class="w-full h-0.5 bg-white rounded-full" />
        <span class="w-full h-0.5 bg-white rounded-full" />
        <span class="w-full h-0.5 bg-white rounded-full" />
      </span>

      <img src="/logo/appguide.svg" width="24" height="24" alt="appguide">
    </button>
    <slot />
  </nav>
</template>

<style scoped lang="scss">
.sidemenu-button {
  // width: 120px;
  height: 100%;

  background-image: url('/assets/textures/ui-sidebar-button.webp');
  background-color: theme('colors.violet.800');
  color: theme('colors.white');
}
</style>
