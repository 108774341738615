<script setup lang="ts">
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'

const bodyElement = ref<HTMLBodyElement | null>(null)
onMounted(() => {
  bodyElement.value = document.querySelector('body')
})

const skeleton = useSideMenuSkeleton()
const open = useSideMenuToggle()

const aside = ref(null)
const { y, arrivedState } = useScroll(aside)
onClickOutside(aside, () => open.value = false)

const trapped = ref(null)
const { activate, deactivate } = useFocusTrap(trapped)
const isLocked = useScrollLock(bodyElement)

function focusActiveItem() {
  const activeItem = document.querySelector('.sidemenu li.active .sidemenu-item')

  if (activeItem)
    activeItem.focus()
}

watch(open, async (value) => {
  await nextTick()

  if (value) {
    activate()
    isLocked.value = true
    focusActiveItem()
  }
  else {
    deactivate()
    isLocked.value = false
  }
})

onKeyStroke('Escape', () => {
  if (open.value)
    open.value = false
})
</script>

<template>
  <div ref="trapped">
    <aside
      ref="aside"
      class="fixed top-0 left-0 flex flex-col min-h-screen z-menu max-h-screen w-full xs:w-[296px] text-white sidemenu"
      :class="{
        'overflow-y-auto': !skeleton,
        'max-lg:hidden': !open,
      }"
    >
      <header
        class="sticky top-0 z-30 flex flex-col items-start justify-center flex-none px-6 h-18"
        :class="{
          'bg-violet-900/30 backdrop-blur': y > 0,
        }"
      >
        <img src="/logo/full-white.svg" alt="AppGuide" width="137" height="28">
        <div v-if="y === 0" class="absolute bottom-0 left-[59px] font-normal leading-3 tracking-wider text-violet-100 font-display">
          <slot name="sub-logo" />
        </div>

        <button
          type="button"
          aria-label="Close menu"
          class="size-12 absolute z-10 top-3 right-5 lg:hidden"
          @click="open = false"
        >
          <i class="i-appguide-x size-3" />
        </button>
      </header>

      <Transition
        mode="out-in"
        enter-active-class="transition duration-150"
        enter-from-class="opacity-0"
        leave-active-class="transition duration-150"
        leave-to-class="opacity-0"
      >
        <div v-if="skeleton" class="relative z-10 flex flex-col gap-3 px-4 pt-5">
          <div v-for="n in 8" :key="n" class="flex gap-3 p-4" :style="{ opacity: `${110 - (n * 10)}%` }">
            <div class="flex-none w-4 h-4 rounded-full bg-skeleton-item" />
            <div class="flex-1 h-4 rounded-full bg-skeleton-item" />
          </div>
        </div>
        <sidemenu-active-indicator v-else class="z-10">
          <ul class="relative z-10 flex flex-col gap-2 px-4 mb-6 mt-7">
            <slot />
          </ul>
        </sidemenu-active-indicator>
      </Transition>

      <Transition
        enter-active-class="transition ease-in-out delay-300 origin-bottom duration-250"
        enter-from-class="scale-y-0"
        leave-active-class="transition duration-150"
        leave-to-class="opacity-0"
      >
        <footer v-if="!skeleton" class="sticky bottom-0 z-20 mt-auto bg-violet-900">
          <ul class="flex gap-2 px-5 py-3" @click.passive="open = false">
            <slot name="footer" />
          </ul>
        </footer>
      </Transition>

      <div class="absolute inset-0 z-0 bg-sidemenu-shadow" />
    </aside>

    <div
      v-if="open"
      aria-hidden="true"
      class="fixed inset-0 bg-gray-900/50 z-menu-backdrop"
    />
  </div>
</template>

<style scoped>
aside {
  background-image: url('/assets/textures/ui-sidebar.webp');
  background-color: theme('colors.violet.800');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-sidemenu-shadow {
  /* background: linear-gradient(180deg, #252540 0%, rgba(37, 37, 64, 0.00) 100%); */
  background: linear-gradient(270deg, #312F4E 0%, rgba(49, 47, 78, 0.00) 45.24%);
  mix-blend-mode: multiply;
  opacity: 0.3;
}

.bg-skeleton-item {
  background: linear-gradient(295deg, rgba(52, 43, 74, 0.00) 4.52%, #3A2549 75.58%);
}
</style>
