<script setup lang="ts">
import type { RawLocation } from '@intlify/vue-router-bridge'
import type { RouteLocation } from 'vue-router'

const props = defineProps<{
  href?: string
  to?: RawLocation | RouteLocation
  external?: boolean
}>()

const emit = defineEmits<{
  click: [MouseEvent]
}>()

const route = useRoute()
const getRouteBaseName = useRouteBaseName()
const routeBaseName = computed(() => getRouteBaseName(route))

const localePath = useLocalePath()

const active = computed(() => {
  if (!props.to)
    return false

  const routeName = typeof props.to === 'string' ? props.to : props.to?.name
  return routeName === routeBaseName.value
})

const element = computed(() => {
  if (props.to)
    return { is: resolveComponent('NuxtLink'), props: { to: localePath(props.to) } }

  if (props.href)
    return { is: resolveComponent('NuxtLink'), props: { to: props.href } }

  return {
    is: 'button',
    props: {
      type: 'button',
    },
  }
})

const open = useSideMenuToggle()
function onClick(e: MouseEvent) {
  emit('click', e)
  open.value = false
}
</script>

<template>
  <li :class="{ active }" class="group">
    <component
      :is="element.is"
      v-bind="element.props"
      tabindex="0"
      :target="element.is !== 'button' && external ? '_blank' : undefined"
      class="relative h-[52px] flex font-display rounded-xl w-full font-bold text-sm leading-[1.125rem] tracking-wide items-center px-4 sidemenu-item border-2 border-transparent transition-colors group-hover:border-white/30 focus:outline-none focus:border-white/30"
      @click="onClick"
    >
      <slot />

      <i-external v-if="external" class="external" />
    </component>
  </li>
</template>

<style lang="scss" scoped>
li :deep(svg), li :deep(i) {
  @apply w-5 h-5 mr-3 text-white -ml-0.5;
}

.external {
  opacity: 0;
  transform: scale(0);
  position: absolute;
  right: 0;
  width: 0.875rem;
  height: 0.875rem;
  color: theme('colors.violet.200');

  transition-duration: 0.2s, 0.15s;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
}

li > button, li > a {
  &:hover, &:focus {
    .external {
      opacity: 1;
      transform: scale(1);
    }
  }
}
</style>
