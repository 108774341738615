<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4258 4.7168C18.5294 4.84405 18.581 5.00588 18.5701 5.16965C18.9915 8.91537 18.9872 11.0954 18.5701 14.8768C18.5683 15.0094 18.5273 15.1384 18.4523 15.2477C18.3774 15.3571 18.2717 15.4417 18.1486 15.4911M1.57434 4.71965C1.48185 4.83263 1.43048 4.97366 1.42863 5.11965C1.18998 7.41382 1.09365 9.14881 1.14546 11"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5576 4.99631C18.5366 4.89404 18.491 4.79843 18.4248 4.71773C16.9191 2.64916 10.9819 1.42773 10.0005 1.42773C9.0205 1.42773 3.40479 2.89059 1.57622 4.71773C1.50976 4.79884 1.46415 4.89496 1.44336 4.99773C1.46505 5.10741 1.51378 5.20993 1.58512 5.29599C1.65647 5.38206 1.74818 5.44895 1.85193 5.49059L9.76193 8.57059H10.2376L18.1491 5.49059C18.2538 5.44981 18.3464 5.38303 18.4181 5.29655C18.4898 5.21007 18.5383 5.10674 18.5591 4.99631H18.5576Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 11V8.57031"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.57485 18.3078H1H2.28667V14.1523H1H3.57485"
      stroke="currentColor"
      stroke-width="1.2862"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.39074 14.1523H7.72857H6.92387C6.45031 14.1523 6.06641 14.5362 6.06641 15.0098V15.3066V15.3726C6.06641 15.8462 6.45031 16.2301 6.92387 16.2301H8.53328C9.00684 16.2301 9.39074 16.614 9.39074 17.0875V17.4503C9.39074 17.9239 9.00684 18.3078 8.53328 18.3078H6.06641"
      stroke="currentColor"
      stroke-width="1.2862"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6758 15.0098C11.6758 14.5362 12.0597 14.1523 12.5332 14.1523H14.1427C14.6162 14.1523 15.0001 14.5362 15.0001 15.0098V17.4503C15.0001 17.9239 14.6162 18.3078 14.1427 18.3078H12.5332C12.0597 18.3078 11.6758 17.9239 11.6758 17.4503V15.0098Z"
      stroke="currentColor"
      stroke-width="1.2862"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
