<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const { t, locale } = useI18n()
const localePath = useLocalePath()

const switchLocalePath = useSwitchLocalePath()
const { changeLocale } = useChatwoot()
watch(locale, () => changeLocale())

const profile = useProfile()
const initial = computed(() => {
  if (!profile.value)
    return '🚫'
  else if (profile.value.first_name && profile.value.last_name)
    return `${profile.value.first_name[0]}${profile.value.last_name[0]}`.toLocaleUpperCase()
  else
    return profile.value.email[0].toLocaleUpperCase()
})

function logout() {
  useAnalytic().logout()
  useTelemetry().logout()

  if (profile.value?.roleId === 3)
    useLibrariesNavigation().selected.value = null

  if (profile.value?.roleId === 4) {
    useClaims().value = []
    useClaim().value = undefined
    useActiveClaim().value = undefined
  }

  useAuthLogout()()
  navigateTo(localePath('index'))
}
</script>

<template>
  <Menu v-slot="{ close }" as="div" class="relative">
    <MenuButton class="flex rounded-full gap-0.5 pl-1 pr-1.5 lg:pl-1.5 items-center border-2 h-9 lg:h-11 hover:border-blue-500 transition-colors">
      <span class="flex items-center justify-center flex-none w-6 h-6 text-xs font-bold text-gray-700 rounded-full lg:w-8 lg:h-8 bg-blue-150">
        {{ initial }}
      </span>
      <span class="flex items-center justify-center w-5 h-5 lg:w-8 lg:h-8">
        <i-navigation />
      </span>
    </MenuButton>

    <Transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems class="absolute right-0 flex flex-col p-1 mt-1 bg-white w-52 shadow-fields-hover rounded-xl">
        <MenuItem v-if="profile?.roleId === 4" v-slot="{ active }">
          <NuxtLink
            :to="localePath('developer-profile')"
            class="flex items-center gap-3 p-2 text-sm font-bold text-left text-gray-700 rounded-lg"
            :class="{ 'bg-gray-100': active }"
            @click.capture="close"
          >
            <i-user-square class="w-6 h-6 text-gray-500" />
            {{ t('profile') }}
          </NuxtLink>
        </MenuItem>

        <MenuItem v-slot="{ active }">
          <button
            type="button"
            class="flex items-center gap-3 p-2 text-sm font-bold text-left rounded-lg text-score-e"
            :class="{ 'bg-score-e/5': active }"
            @click="logout()"
          >
            <i-logout class="w-6 h-6" />
            {{ t('logout') }}
          </button>
        </MenuItem>

        <hr class="my-2 border-gray-200">

        <MenuItem v-slot="{ active }">
          <NuxtLink
            :to="switchLocalePath(locale === 'en' ? 'fr' : 'en')"
            class="flex items-center gap-3 p-2 text-sm font-bold text-left text-gray-700 rounded-lg"
            :class="{ 'bg-gray-100': active }"
            @click.capture="close"
          >
            <i-language class="w-6 h-6 text-gray-500" />
            {{ locale === 'en' ? 'Français' : 'English' }}
          </NuxtLink>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<i18n lang="json">
{
  "en": {
    "profile": "My Profile",
    "logout": "Log Out"
  },
  "fr": {
    "profile": "Mon profil",
    "logout": "Se déconnecter"
  }
}
</i18n>
