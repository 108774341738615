<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2441 5.57621C14.85 5.2179 14.3458 5.00429 13.8143 4.9705C11.6571 4.81713 9.49162 4.81713 7.33434 4.9705C6.17663 5.05393 5.29206 5.91679 5.23949 6.96707C5.15711 8.64352 5.11634 10.3217 5.1172 12.0002C5.1172 13.7282 5.15949 15.4116 5.23949 17.0345C5.29206 18.0848 6.17663 18.9476 7.33434 19.0299C9.49162 19.1834 11.6571 19.1834 13.8143 19.0299C14.3454 18.9959 14.8492 18.7823 15.2429 18.4242"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.18555 12H19.3135"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0645 8.75C17.3639 9.40029 18.6645 10.7009 19.3136 12.0003C18.6645 13.2997 17.3639 14.6003 16.0645 15.2506"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
