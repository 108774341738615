<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.91016 7.00709C7.9593 6.75223 9.73301 6.74309 12.949 7.00709"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.92969 4.99805V6.70662"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66797 7.00781C6.66797 8.78153 8.11483 10.3301 10.1983 11.7164"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.19 7.00781C11.2403 9.18038 9.01973 11.0158 6.16602 12.6272"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.7617 19.05L14.4303 11.0443C14.4972 10.8442 14.6253 10.6701 14.7966 10.5469C14.9679 10.4236 15.1736 10.3573 15.3846 10.3574C15.5953 10.3575 15.8006 10.4237 15.9716 10.5467C16.1426 10.6698 16.2707 10.8434 16.3377 11.0431L19.0063 19.05"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.7285 16.1523H18.0405"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
