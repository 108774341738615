<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9288 18.9288L15.7031 15.7031"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.46415 17.858C14.8356 17.858 17.857 14.8366 17.857 9.46512C17.857 4.09369 14.8356 1.07227 9.46415 1.07227C4.09272 1.07227 1.07129 4.09369 1.07129 9.46512C1.07129 14.8366 4.09272 17.858 9.46415 17.858Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
