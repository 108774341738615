<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19147 15.974L1.11719 10.1512C3.37576 6.46118 5.21862 4.3469 9.21433 1.59976C9.44546 1.44065 9.71945 1.35547 10 1.35547C10.2806 1.35547 10.5546 1.44065 10.7858 1.59976C14.7815 4.34547 16.6243 6.45976 18.8829 10.1498L18.81 15.9726C18.7329 16.6373 18.4336 17.2565 17.9607 17.7299C17.4878 18.2033 16.8689 18.5033 16.2043 18.5812C12.0809 19.0411 7.91916 19.0411 3.79576 18.5812C3.13141 18.503 2.51288 18.2029 2.04025 17.7295C1.56763 17.2561 1.26857 16.6371 1.19147 15.9726V15.974Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.3812 18.8835V13.9392C12.389 13.6215 12.3331 13.3054 12.2169 13.0096C12.1007 12.7138 11.9265 12.4442 11.7045 12.2167C11.4825 11.9892 11.2173 11.8085 10.9244 11.6851C10.6315 11.5616 10.3169 11.498 9.99907 11.498C9.68124 11.498 9.36662 11.5616 9.07373 11.6851C8.78084 11.8085 8.5156 11.9892 8.29363 12.2167C8.07166 12.4442 7.89744 12.7138 7.78122 13.0096C7.66501 13.3054 7.60914 13.6215 7.61693 13.9392V18.8835"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

  </svg>
</template>
