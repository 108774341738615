<script setup lang="ts">
import type { RawLocation } from '@intlify/vue-router-bridge'
import type { RouteLocation } from 'vue-router'

const props = defineProps<{
  href?: string
  to?: RawLocation | RouteLocation
}>()

const localePath = useLocalePath()

const element = computed(() => {
  if (props.to)
    return { is: resolveComponent('NuxtLink'), props: { to: localePath(props.to) } }

  if (props.href)
    return { is: resolveComponent('NuxtLink'), props: { to: props.href } }

  return {
    is: 'button',
    props: {
      type: 'button',
    },
  }
})
</script>

<template>
  <li class="contents group">
    <component
      :is="element.is"
      v-bind="element.props"
      class="flex flex-col items-center justify-center flex-1 pt-3 pb-2 text-xs transition-colors font-semibold rounded-md hover:bg-violet-400 font-display"
    >
      <slot />
    </component>
  </li>
</template>

<style lang="scss" scoped>
li :deep(svg) {
  @apply w-4 h-4 mb-2;
}
</style>
