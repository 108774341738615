<script setup lang="ts">
const hover = useState<boolean>('dev-claims-dropdown-hover')
const size = useState('dev-claims-dropdown-size', () => ({ width: 0, height: 0 }))
</script>

<template>
  <div id="under-navbar" class="z-40 flex items-center pr-4 md:pr-10 lg:px-10 xl:px-24">
    <div class="w-[120px] mr-6 md:mr-10 lg:hidden" />

    <div
      class="app-highlight"
      :class="{ hover }"
      :style="{
        width: `${size.width}px`,
        height: `${size.height}px`,
      }"
    />
  </div>
</template>

<style scoped lang="scss">
.app-highlight {
  background: var(--dominant-color, transparent);
  filter: blur(32px);
  opacity: 0.8;
  transition: transform 0.2s, opacity 0.2s;

  @screen md {
    opacity: 0.4;
  }

  @screen lg {
    opacity: 0.8;
  }

  &.hover {
    opacity: 1;
    transform: translateY(6px);
  }
}
</style>
