<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2339 17.7537C15.9855 16.8206 15.4358 15.9958 14.6703 15.4074C13.9047 14.819 12.9663 14.5 12.0007 14.5C11.0352 14.5 10.0967 14.819 9.33118 15.4074C8.56565 15.9958 8.01596 16.8206 7.76758 17.7537"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0008 19.4294C16.7551 19.4294 19.4294 16.7551 19.4294 12.0008C19.4294 7.24655 16.7551 4.57227 12.0008 4.57227C7.24655 4.57227 4.57227 7.24655 4.57227 12.0008C4.57227 16.7551 7.24655 19.4294 12.0008 19.4294Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9994 12.6641C13.5994 12.6641 14.5 11.7646 14.5 10.1646C14.5 8.56463 13.5994 7.66406 11.9994 7.66406C10.3994 7.66406 9.5 8.56463 9.5 10.1646C9.5 11.7646 10.3994 12.6641 11.9994 12.6641Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
