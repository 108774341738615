<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.5 7.99996C6.5 8.39778 6.65803 8.77931 6.93934 9.06061C7.22064 9.34191 7.60217 9.49995 7.99999 9.49995C8.39781 9.49995 8.77934 9.34191 9.06064 9.06061C9.34195 8.77931 9.49998 8.39778 9.49998 7.99996C9.49998 7.60214 9.34195 7.22061 9.06064 6.93931C8.77934 6.658 8.39781 6.49997 7.99999 6.49997C7.60217 6.49997 7.22064 6.658 6.93934 6.93931C6.65803 7.22061 6.5 7.60214 6.5 7.99996ZM6.5 3.49999C6.5 3.89781 6.65803 4.27934 6.93934 4.56064C7.22064 4.84195 7.60217 4.99998 7.99999 4.99998C8.39781 4.99998 8.77934 4.84195 9.06064 4.56064C9.34195 4.27934 9.49998 3.89781 9.49998 3.49999C9.49998 3.10217 9.34195 2.72064 9.06064 2.43934C8.77934 2.15803 8.39781 2 7.99999 2C7.60217 2 7.22064 2.15803 6.93934 2.43934C6.65803 2.72064 6.5 3.10217 6.5 3.49999ZM6.5 12.4999C6.5 12.8978 6.65803 13.2793 6.93934 13.5606C7.22064 13.8419 7.60217 13.9999 7.99999 13.9999C8.39781 13.9999 8.77934 13.8419 9.06064 13.5606C9.34195 13.2793 9.49998 12.8978 9.49998 12.4999C9.49998 12.1021 9.34195 11.7206 9.06064 11.4393C8.77934 11.158 8.39781 10.9999 7.99999 10.9999C7.60217 10.9999 7.22064 11.158 6.93934 11.4393C6.65803 11.7206 6.5 12.1021 6.5 12.4999Z" fill="#334155" />
  </svg>
</template>
