<script setup lang="ts">
const indicator = ref({
  visible: false,
  top: 0,
  left: 0,
  height: 0,
  width: 0,
})

async function updateIndicatorState() {
  const container = document.querySelector('.sidemenu-list')
  const activeElement = document.querySelector('.sidemenu-list li.active')

  if (!activeElement || !container) {
    indicator.value.visible = false
    return
  }

  const { top: containerTop } = container.getBoundingClientRect()
  const { top: activeTop, left, height, width } = activeElement.getBoundingClientRect()

  indicator.value = {
    visible: true,
    top: activeTop - containerTop,
    left,
    height,
    width,
  }
}

const route = useRoute()
watch(() => route.name, async () => {
  await nextTick()
  updateIndicatorState()
})

onMounted(() => updateIndicatorState())
const open = useSideMenuToggle()
watch(open, async () => {
  await nextTick()
  updateIndicatorState()
})

const activeIdCookie = useActiveClaim()
watch(activeIdCookie, async () => {
  await nextTick()
  updateIndicatorState()
})

const onResize = useDebounceFn(() => {
  updateIndicatorState()
}, 500, { maxWait: 1500 })

onMounted(() => {
  window.addEventListener('resize', onResize)
})
</script>

<template>
  <div class="relative sidemenu-list">
    <div
      class="absolute top-0 transition pointer-events-none left-4 rounded-xl glass-indicator"
      :class="{
        'opacity-0': !indicator.visible,
        'opacity-100': indicator.visible,
      }"
      :style="{
        transform: `translateY(${indicator.top}px)`,
        height: `${indicator.height}px`,
        width: `${indicator.width}px`,
      }"
    />

    <slot />
  </div>
</template>

<style scoped lang="scss">
.glass-indicator {
  box-shadow: 0px 8px 88px 0px rgba(42, 40, 72, 0.60);

  &:before,
  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: theme('borderRadius.xl');
  }

  &:after {
    padding: 2px;
    background: linear-gradient(287deg, #605C71 54.2%, #7F7592 87.43%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:before {
    opacity: 0.5;
    background: linear-gradient(295deg, #2E2844 4.52%, #2F203F 75.58%);
  }
}
</style>
